* {
  font-weight: 700;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: no-repeat;
  background-size: cover;
}


.login-page{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.loginTitle {
  display: flex;
  justify-content: center;
  font-size: 48px;
  margin-bottom: 10px;
  color: #000;

}
.subtitle{
  color: #000;
}

.form {
  display: grid;
  margin: 30px;
  width: 450px;
}



@media (max-width: 550px) {
  .form {
    width: 95%;
  }
}

input::-webkit-input-placeholder,
input::-moz-placeholder,
input::-ms-input-placeholder,
input::placeholder {
  position: absolute;
  margin-top: 18px;
  color: #000;
}

input {
  padding-left: 75px;
}

.input {
  border-radius: 10px;
  height: 50px;
  background: none;
  width: 100%;
  border: 1px solid #000;
  margin-top: 20px;
  color: #000; /* Set text color to white */

}

.loginBtn {
  border-radius: 10px;
  height: 50px;
  background: none;
  width: 100%;
  border: 2px solid #452f87;
  margin-top: 20px;
  font-size: medium;
  color: #452f87;
  cursor: pointer;
}

.loginBtn:active {
  opacity: 50%;
}

.loginBtn:hover {
  color: white;
  background-color: #452f87;
  border: 2px solid #452f87;
}

.icon {
  position: absolute;
  margin-top: 30px;
  margin-left: 20px;
  color: #000;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}

.forgot-password {
  text-align: center;
  margin-top: 50px;
}

.forgot-password-link {
  color: #452f87; /* Set your preferred link color */
  text-decoration: none;
  font-size: 14px;
}

.forgot-password-link:hover {
  text-decoration: underline;
}